<template>
	<div id="classRewards">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">文明班评比</a></el-breadcrumb-item>
			</el-breadcrumb>

      <v-weburl-iframe class="content" url="moral/fine" />
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import VWeburlIframe from "@/components/weburl-iframe/index.vue";
export default {
	components: {
    VWeburlIframe,
		index
	}
};
</script>

<style lang="scss">
</style>
